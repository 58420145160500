import { createGlobalStyle } from "styled-components";
import i18n from "./i18n";

const languageSwitcher = (language) => {
  switch (language) {
    case "en-US":
      return `Arial`;
    case "zh-CN":
      return `"Microsoft JhengHei", "微軟正黑體", "Arial"`;
    case "zh-TW":
      return `"Microsoft JhengHei", "微軟正黑體"`;
    case "th-TH":
      return ``;
    default:
      return "";
  }
};
export const GlobalStyles = createGlobalStyle`
    @media (max-width: 800px) {
      * {
         font-family: ${languageSwitcher(i18n.language)};
        }
     }

`;
