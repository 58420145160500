import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n.on("languageChanged", function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  //   if (lng === i18n.options.fallbackLng[0]) {
  //     if (window.location.pathname.includes("/" + i18n.options.fallbackLng[0])) {
  //       const newUrl = window.location.pathname.replace(
  //         "/" + i18n.options.fallbackLng[0],
  //         ""
  //       );
  //       window.location.replace(newUrl);
  //     }
  //   }
  //  window.localStorage.setItem("i18nextLng", lng);
});

const languageInLocalStorage = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "en-US": {
        translation: require("../assets/translation/en-US.json"),
      },
      "zh-CN": {
        translation: require("../assets/translation/zh-CN.json"),
      },
      "zh-TW": {
        translation: require("../assets/translation/zh-TW.json"),
      },
    },
    whitelist: ["en-US", "zh-TW", "zh-CN"],
    fallbackLng: languageInLocalStorage ? languageInLocalStorage : "en-US",
    detection: {
      order: ["path"],
      lookupFromPathIndex: 1,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
    react: {
      wait: true,
    },
    debug: false,
  });

export default i18n;
