const homepage = [
  {
    title: "slots",
    position: "left",
  },
  {
    title: "egame",
    position: "left",
  },
  {
    title: "sports",
    position: "left",
  },
  {
    title: "esports",
    position: "left",
  },
  {
    title: "horserace",
    position: "left",
  },
  {
    title: "live",
    position: "left",
  },
  {
    title: "fish",
    position: "left",
  },
  {
    title: "qipai",
    position: "right",
  },
  {
    title: "cockfight",
    position: "left",
  },
  {
    title: "lottery",
    position: "right",
  },
];

const brands = [
  { name: "pp", gametype: ["slots", "egame", "sports", "live"] },
  { name: "haba", gametype: ["slots", "egame"] },
  { name: "jdb", gametype: ["slots", "fish"] },
  { name: "sw", gametype: ["slots", "egame"] },
  { name: "rt", gametype: ["slots", "egame"] },
  { name: "jili", gametype: ["slots", "fish"] },
  { name: "nt", gametype: ["slots"] },
  { name: "awc_spade", gametype: ["slots", "egame", "fish"] },
  { name: "awc_facai", gametype: ["slots", "egame", "fish"] },
  { name: "awc_pgs", gametype: ["slots", "egame"] },
  { name: "endorphina", gametype: ["slots"] },
  { name: "awc_fs", gametype: ["slots"] },
  { name: "joker", gametype: ["slots", "fish"] },
  { name: "nlc", gametype: ["slots"] },
  { name: "btg", gametype: ["slots"] },
  { name: "imes", gametype: ["esports"] },
  { name: "avia", gametype: ["esports"] },
  { name: "tf", gametype: ["esports"] },
  { name: "wgfm", gametype: ["horserace"] },
  { name: "awc_rcb", gametype: ["horserace"] },
  { name: "ly", gametype: ["qipai", "fish"] },
  { name: "ky", gametype: ["qipai", "fish"] },
  { name: "365", gametype: ["qipai"] },
  { name: "awc_km", gametype: ["egame"] },
  { name: "awc_ludo", gametype: ["egame"] },
  { name: "awc_yl", gametype: ["egame", "fish"] },
  { name: "awc_cockfight", gametype: ["cockfight"] },
  { name: "sm01", gametype: ["cockfight"] },
  { name: "saba", gametype: ["sports"] },
  { name: "sbo", gametype: ["sports"] },
  { name: "bc", gametype: ["sports"] },
  { name: "wg", gametype: ["sports"] },
  { name: "bti", gametype: ["sports"] },
  { name: "phsb", gametype: ["sports"] },
  { name: "pinnacle", gametype: ["sports"] },
  { name: "vr", gametype: ["lottery"] },
  { name: "tcg_sea", gametype: ["lottery"] },
  { name: "tcg_lotto", gametype: ["lottery"] },
  { name: "tcg_lotto_vn", gametype: ["lottery"] },
  { name: "bg", gametype: ["fish", "live"] },
  { name: "ag", gametype: ["live"] },
  { name: "sg", gametype: ["live"] },
  { name: "sw", gametype: ["live"] },
  { name: "evo", gametype: ["live"] },
  { name: "ab", gametype: ["live"] },
  { name: "dg", gametype: ["live"] },
  { name: "wm", gametype: ["live"] },
  { name: "awc_venus", gametype: ["live"] },
];

const benefits = [
  {
    icon: "film",
  },
  {
    icon: "card",
  },
  {
    icon: "file",
  },
  {
    icon: "wallet",
  },
  {
    icon: "browser",
  },
  {
    icon: "brief",
  },
  {
    icon: "secure",
  },
  {
    icon: "fast",
  },
  {
    icon: "technical",
  },
];

const demoWeb = [
  { image: "/demo/web/t1.jpg", title: "T1", link: "https://t1.srvptt.com/" },
  { image: "/demo/web/t2.jpg", title: "T2", link: "https://t2.srvptt.com/" },
  { image: "/demo/web/t3.jpg", title: "T3", link: "https://t3.srvptt.com/" },
  // { image: "/demo/web/t4.jpg", title: "T4", link: "https://t4.srvptt.com/" },
  // { image: "/demo/web/t5.jpg", title: "T5", link: "https://t5.srvptt.com/" },
  { image: "/demo/web/t8.jpg", title: "T8", link: "https://t5.srvptt.com/" },
  {
    image: "/demo/web/t10.jpg",
    title: "T10",
    link: "https://t5.srvptt.com/",
  },
  // {
  //   image: "/demo/web/t11.jpg",
  //   title: "T11",
  //   link: "https://t5.srvptt.com/",
  // },
  /*
  {
    image: "/demo/web/t11-1.jpg",
    title: "T11-1",
    link: "https://t5.srvptt.com/",
  },
  */
  // {
  //   image: "/demo/web/t13.jpg",
  //   title: "T13",
  //   link: "https://t5.srvptt.com/",
  // },

  // {
  //   image: "/demo/web/t14.jpg",
  //   title: "T14",
  //   link: "https://t5.srvptt.com/",
  // },
  // {
  //   image: "/demo/web/t15.jpg",
  //   title: "T15",
  //   link: "https://t5.srvptt.com/",
  // },
  { image: "/demo/web/t20.png", title: "T20", link: "https://t20.srvptt.com/" },
  { image: "/demo/web/t29.png", title: "T29", link: "https://t29.srvptt.com/" },
  { image: "/demo/web/t36.png", title: "T36", link: "https://t36.srvptt.com/" },
];

const demoWap = [
  { image: "/demo/wap/t1.png", title: "T1", link: "https://t1.srvptt.com/" },
  { image: "/demo/wap/t2.png", title: "T2", link: "https://t2.srvptt.com/" },

  { image: "/demo/wap/t3.png", title: "T3", link: "https://t3.srvptt.com/" },
  // { image: "/demo/wap/t4.png", title: "T4", link: "https://t4.srvptt.com/" },
  // { image: "/demo/wap/t5.png", title: "T5", link: "https://t5.srvptt.com/" },
  { image: "/demo/wap/t8.png", title: "T8", link: "https://t5.srvptt.com/" },

  {
    image: "/demo/wap/t10.png",
    title: "T10",
    link: "https://t5.srvptt.com/",
  },
  // {
  //   image: "/demo/wap/t11.png",
  //   title: "T11",
  //   link: "https://t5.srvptt.com/",
  // },
  // {
  //   image: "/demo/wap/t13.png",
  //   title: "T13",
  //   link: "https://t5.srvptt.com/",
  // },
  // {
  //   image: "/demo/wap/t14.png",
  //   title: "T14",
  //   link: "https://t5.srvptt.com/",
  // },
  // {
  //   image: "/demo/wap/t15.png",
  //   title: "T15",
  //   link: "https://t5.srvptt.com/",
  // },
  { image: "/demo/wap/t20.png", title: "T20", link: "https://t20.srvptt.com/" },
  { image: "/demo/wap/t29.png", title: "T29", link: "https://t29.srvptt.com/" },
  { image: "/demo/wap/t36.png", title: "T36", link: "https://t36.srvptt.com/" },
];

const supports = [
  {
    icon: "skype",
    name: "Skype",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  {
    icon: "wechat",
    name: "WeChat",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  {
    icon: "whatsapp",
    name: "WhatsApp",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  {
    icon: "telegram",
    name: "Telegram",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  {
    icon: "kakaotalk",
    name: "KaKaoTalk",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  {
    icon: "viber",
    name: "Viber",
    handle: "@PGGAME",
    url: "https://testing.com",
  },
  { icon: "line", name: "LINE", handle: "@PGGAME", url: "https://testing.com" },
];

const appbarMenu = [
  { title: "about", withBorder: false, route: "" },
  { title: "products", withBorder: false, route: "products" },
  { title: "demo", withBorder: false, route: "demo" },
  { title: "contact", withBorder: false, route: "contact" },
];

export const hardcoded = {
  homepage,
  brands,
  benefits,
  demoWeb,
  demoWap,
  supports,
  appbarMenu,
};
