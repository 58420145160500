import React, { useState } from "react";
import Page from "../../component/page";
import { common } from "../../utils/common";
import styled from "styled-components";
import { hardcoded } from "../../utils/hardcode";
import breakpoint from "../../config/breakpoints";
import Footer from "../../component/footer";
import Modal from "../../component/modal";
import QRCode from "react-qr-code";
import AppBar from "../../component/appbar";
import i18n from "../../config/i18n";

const Content = styled.div`
  min-height: 100vh;
  @media (${breakpoint.mobile}) {
    padding: 0px 40px 20px 40px;
  }
`;

const TitleHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  line-height: 0.85;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;

  @media (${breakpoint.mobile}) {
    padding-top: 0px;
    padding-bottom: 40px;
  }
`;

const Title = styled.div`
  font-size: 67px;
  font-weight: 600;
  @media (${breakpoint.mobile}) {
    font-size: 40px;
    font-weight: bold;
  }
`;

const Subtittle = styled.div`
  font-weight: bold;
  color: #00a8ff;
  font-size: 30px;
  @media (${breakpoint.mobile}) {
    font-size: 20px;
  }
`;

const SupportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  gap: 50px;

  @media (${breakpoint.mobile}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }
`;

const SupportBackground = styled.div`
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-top;
  gap: 50px;
  color: #fff;
  padding: 40px;
  cursor: pointer;

  background: rgb(0, 40, 100);
  @media (${breakpoint.mobile}) {
    padding: 20px;
    gap: 10px;
    background: #001731;
  }
`;

const SupportTitle = styled.div`
  @media (${breakpoint.mobile}) {
    font-size: 12px;
  }
`;
const SupportDescription = styled.div`
  font-size: 12px;
`;

const Logos = styled.img`
  width: 150px;
  height: auto;

  @media (${breakpoint.mobile}) {
    width: 60%;
  }
`;

const Supports = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <>
      <SupportBackground
        onClick={() => {
          setOpen(true);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Logos
          src={
            common.imageUrl +
            "contact/icons/" +
            data.icon +
            (hover ? "-hover" : "") +
            ".png"
          }
        />
        <SupportTitle>{data.name}</SupportTitle>
        <SupportDescription>{data.handle}</SupportDescription>
      </SupportBackground>
      <Modal
        open={open}
        title={data.name}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div style={{ padding: 50 }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={data.url}
            viewBox={`0 0 256 256`}
          />
        </div>
      </Modal>
    </>
  );
};
const Contact = () => {
  return (
    <>
      <AppBar />
      <Page
        background={common.imageUrl + "/contact/bg.jpg"}
        style={{ paddingBottom: 10 }}
      >
        <Content>
          <TitleHolder>
            <Subtittle>CONSULT</Subtittle>
            <Title>{i18n.t("menu.contact")}</Title>
          </TitleHolder>
          <SupportsContainer>
            {hardcoded.supports.map((data, index) => {
              return <Supports data={data} />;
            })}
          </SupportsContainer>
        </Content>
      </Page>
      <Footer />
    </>
  );
};

export default Contact;
